//____ HEADER - LANGS MENU ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_langs_menu() {
    $("[data-lang]").on("click",function(e){
        var $el = $(this).parents(".part_lang");
        e.preventDefault();
        e.stopPropagation();

        $.fn.part_lang_close = function() {
            $(this).removeClass("toggled").find(".list").stop(true).slideUp(500, function(){$el.removeClass("toggle")});
        };
        $.fn.part_lang_open = function() {
            $(this).addClass("toggled toggle").find(".list").stop(true).slideDown(500);
        };

        $("html").one("click", function() {
            $el.part_lang_close();
        });
        if ($el.hasClass("toggled")) {
            $el.part_lang_close();
        } else {
            $el.part_lang_open();
        }
    });
}