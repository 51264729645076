var fn_google_map = function(element) {
    var map_id = element;

    $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCyF7U9Lgn6wFqZ0ML01kw6ndzB3i86ulg").done(function() {

        //--- GOOGLE MAP --- //
        var myLatlng = {
            lat: parseFloat($("#" + map_id).data("lat")),
            lng: parseFloat($("#" + map_id).data("lng"))
        };


        var map = new google.maps.Map(document.getElementById(map_id), {
            zoom: 17,
            center: myLatlng,
            scrollwheel: false,
            draggable: false
        });

        var marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: 'Click to zoom'
        });

        map.addListener('center_changed', function() {
            // 3 seconds after the center of the map has changed, pan back to the
            // marker.
            window.setTimeout(function() {
                map.panTo(marker.getPosition());
            }, 3000);
        });

        marker.addListener('click', function() {
            map.setZoom(8);
            map.setCenter(marker.getPosition());
        });
    })
};