function fn_layout_main() {
    var $body = $("body");

    setTimeout(function(){
        $body.removeClass("preload").addClass("loaded");
        setTimeout(function () {
            $body.removeClass("loaded");
        }, 500);
    }, 300);

    var pull = $('#pull');
    var menu = $('.menu');
    var header = $('#main_header');

    $(pull).on('click', function (e) {
        e.preventDefault();
        menu.stop().slideToggle("700");
        pull.find(".bind--menu-toggle").toggleClass("open");
        header.toggleClass('active');
        $('body').toggleClass('no-scroll');

        return false;
    });

    var map_on_page = "map-canvas";

    if($("#" + map_on_page).length) {
        fn_google_map(map_on_page);
    };

    $(document).on('click', 'a.drop', function (event) {
        event.preventDefault();
        $(this).parent().parent().parent().find('.drop_content').stop().slideToggle();
        $(this).toggleClass("active");
    });

    var lg = $(".gallery-bind");
    if(lg.length) {
        lg.lightGallery({
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false
        });
    }
}

if ($("#comp-contact").length) {
    setTimeout(function () {
        $("#comp-contact").append('<input name="antispam" value="antispam" type="hidden" />');
    }, 2500);
}