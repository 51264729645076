// --- LAYOUT, COMPONENTS, PARTS --- //
$(function () {
    fn_layout_main();
});

// --- MISC --- //
$(function () {
    nl_fn_cookie();
    nl_fn_footer();
    nl_fn_wsw();
    nl_fn_anchor_link();
});