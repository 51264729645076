//____ ANCHOR LINKS ANIMATIONS ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_anchor_link() {
    var anchor_link = $("a[href^='#anchor-']");
    anchor_link.on("click", function (e) {
        var id = $(this).attr("href");
        if ($(id).length) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(id).offset().top - 5
            }, 500);
        }
    });
    if(window.location.hash) {
        if ($(window.location.hash).length) {
            $('html, body').animate({
                scrollTop: $(window.location.hash).offset().top - 5
            }, 0);
        }
    }
}