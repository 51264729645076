$.fn.nl_google_map = function () {
    var $map = $(this);
    if (!$map.find(".gm-style").length) {
        $.getScript("https://maps.googleapis.com/maps/api/js")
            .done(function () {
                //--- GOOGLE MAP --- //
                var coords = $map.data('coords').split(/[\s,]+/);

                var mapOptions = {
                    zoom: 14,
                    scrollwheel: false,
                    draggable: false,
                    mapTypeControl: false,
                    disableDefaultUI: false,
                    center: {
                        lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                    }
                };

                var map = new google.maps.Map($map[0], mapOptions);

                var marker = new google.maps.Marker({
                    title: $map.data('title'),
                    icon: '../img/marker.png',
                    position: mapOptions.center,
                    url: $map.data('url')
                });

                marker.setMap(map);

                function loadURL(marker) {
                    return function () {
                        window.open(marker.url);
                    }
                }

                google.maps.event.addListener(marker, 'click', loadURL(marker));

                google.maps.event.addListener(map, 'click', function () {
                    map.setOptions({
                        scrollwheel: true, draggable: true
                    });
                });
            });
    }
};