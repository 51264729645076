//____ TABS SWITCH CONTENT ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
$.fn.nl_switch_content = function (slide, closable, slick) {
    var nl_fn_switch_class = $(this);
    nl_fn_switch_class.find(".switch-content").hide();
    nl_fn_switch_class.find(".switch-content.active").show();
    var button = nl_fn_switch_class.find(".switch-nav").find(".switch-button");
    var content = nl_fn_switch_class.find(".switch-area").find(".switch-content");
    content.each(function(n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
    });
    button.each(function(n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
        $(this).on("click", function (e) {
            e.preventDefault();
            if (!$(this).hasClass("active")) {
                if (slide == true) {
                    if ($(".switch-button").hasClass("active")) {
                        $(this).closest(".switch-nav").find(".switch-button").removeClass("active");
                        $(this).addClass("active");
                        $(this).closest(nl_fn_switch_class).find(".switch-area").find(".switch-content").stop(true).slideUp(500).removeClass("active");
                        $(this).closest(nl_fn_switch_class).find(".switch-area").find("."+ button_class).delay(500).slideDown(500).addClass("active");
                    } else {
                        $(this).closest(".switch-nav").find(".switch-button").removeClass("active");
                        $(this).addClass("active");
                        $(this).closest(nl_fn_switch_class).find(".switch-area").find(".switch-content").stop(true).slideUp(500).removeClass("active");
                        $(this).closest(nl_fn_switch_class).find(".switch-area").find("."+ button_class).slideDown(500).addClass("active");
                    }
                } else {
                    $(this).closest(".switch-nav").find(".switch-button").removeClass("active");
                    $(this).addClass("active");
                    $(this).closest(nl_fn_switch_class).find(".switch-area").find(".switch-content").hide().css("opacity","0").removeClass("active");
                    $(this).closest(nl_fn_switch_class).find(".switch-area").find("."+ button_class).show().css("opacity","1").addClass("active");
                }
                if (slick == true) {
                    content.find('.slick-slider').each(function(){
                        $(this).slick("reinit");
                    });
                }
            } else if (closable == true) {
                $(this).closest(".switch-nav").find(".switch-button").removeClass("active");
                if (slide == true) {
                    $(this).closest(nl_fn_switch_class).find(".switch-area").find("."+ button_class).slideUp(500).removeClass("active");
                } else {
                    $(this).closest(nl_fn_switch_class).find(".switch-area").find("."+ button_class).hide().css("opacity","0").removeClass("active");
                }
            }
        });
    });
};